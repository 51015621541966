<template>
  <div>
    <div class="topNavBox">
      <div class="topbox clearfix">
        <a href="javaScript:" class="fl back" @click="seeBack">
          <img src="~img/back@2x.png" alt="" />
        </a>
        <p class="fl">修改暱稱</p>
      </div>
    </div>
    <div class="nick">
      <input
        type="text"
        placeholder="暱稱"
        @keyup="getFocus"
        v-model="nickName"
      />
    </div>
    <button :class="{ submitBtn: true, blue: isBlue }" @click="submits">
      提交
    </button>
  </div>
</template>
<script>
import { changeUsername } from "apiurl/user";
import { isNull } from "utils/core/kernel";
export default {
  data() {
    return {
      isBlue: false,
      nickName: ""
    };
  },
  methods: {
    seeBack() {
      this.$router.go(-1);
    },
    getFocus() {
      if (isNull(this.nickName)) {
        this.isBlue = false;
      } else {
        this.isBlue = true;
      }
    },
    submits() {
      var reg = /^[\w\u4e00-\u9fa5]{2,10}$/;
      if (reg.test(this.nickName)) {
        changeUsername({ userName: this.nickName }).then(res => {
          if (res.success) {
            this.Toast({
              message: "修改成功",
              duration: 2000,
              position: "top"
            });
            this.$router.go(-1);
          }
        });
      } else {
        this.Toast({
          message: "2~10位漢字字母符號",
          duration: 2000,
          position: "top"
        });
      }
    }
  }
};
</script>

<style lang="stylus" scoped>
@import '~@/styles/cominxin.styl'
.nick
    margin 40px 51px 0 44px
    input
        padding 19px 0px
        width 100%
        font-size 26px
        border-bottom 2px solid #D7D7D7
        placeMain(#CFCFCF, 26px, 0px)
.submitBtn
    width 332px
    height 56px
    background rgba(216, 216, 216, 1)
    border-radius 28px
    font-size 28px
    color #fff
    display block
    margin 298px auto 0
    &.blue
        background linear-gradient(135deg, rgba(122, 209, 255, 1) 0%, rgba(29, 133, 241, 1) 100%)
        box-shadow 0px 1px 8px 0px rgba(118, 206, 254, 1)
</style>
